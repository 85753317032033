@media only screen and (max-width: 767px) {
    .exchange-tab,
    .cfd-tab {
        width: auto !important;

        .nav-item {
            width: auto !important;
        }
    }
}

@media only screen and (max-width: $break-xsmall - 1px) {
    .mobile-close {
        display: flex;
    }
}

@media only screen and (max-width: $break-xsmall - 1px) {
    .pd-left-xsmall {
        padding-left: 20px !important;
    }
}
