.btn-loading {
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner-border {
        margin-left: 10px;
        width: 1.2rem;
        height: 1.2rem;
        font-size: 0.9rem;
    }
}

.btn-sm-loading {
    .spinner-border {
        margin-left: 10px;
        width: 1.2rem;
        height: 1.2rem;
        font-size: 0.9rem;
    }
}

.check-invalid {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
}
