html {
    * {
        box-sizing: border-box;
    }
}

/***********Body*********/
body {
    @include transition(ease 0.1s);
    background-color: var(--body-color);
    color: var(--color-500);
    font-weight: font-weight-normal;
    font-family: $font-Plex;
    font-size: 15px;

    &.font-opensans {
        font-family: $font-opensans;
    }

    &.font-poppins {
        font-family: $font-poppins;
        font-size: 15px;
    }

    &.font-montserrat {
        font-family: $font-montserrat;
        font-size: 15px;
    }

    &.font-Plex {
        font-family: $font-Plex;
        font-size: 15px;
    }

    @media only screen and (max-width: 554px) {
        font-size: 13px;
    }

    a {
        color: var(--text-color);
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: var(--secondary-color);
        }
    }
}

/***********Invoicepage & purchasepage tab*********/
.invoice-set {
    &.nav-tabs {
        .nav-link {
            @media only screen and (max-width: $break-xxsmall) {
                width: 100px;
                text-align: center;
            }
        }
    }
}

/***********Changelog Button-size*********/
.mx-size {
    @media only screen and (min-width: 480px) {
        max-width: 320px;
    }
}

/***********Login-page responsive*********/
.auth-detailblock {
    strong {
        @media only screen and (max-width: 480px) {
            font-size: 12px;
        }
    }
}

.auth-h100 {
    height: calc(100vh - 192px);

    @media only screen and (max-width: $break-1280) {
        height: calc(100vh - 96px);
    }
}

/**********profile*********/
.profile-fulldeatil {
    @media only screen and (max-width: $break-xxsmall) {
        flex-direction: column;
    }

    .profile-info {
        &.border-start {
            @media only screen and (max-width: $break-xxsmall) {
                border-left: 0px !important;
                padding-left: 0px !important;
            }
        }
    }

    .profile-av {
        @media only screen and (max-width: $break-xxsmall) {
            text-align: left !important;
        }
    }

    .about-info {
        @media only screen and (max-width: $break-xxsmall) {
            justify-content: flex-start !important;
        }
    }
}

/***********Comman Row*********/
.row-deck {
    > .col,
    > [class*='col-'] {
        display: flex;
        align-items: stretch;

        .card {
            flex: 1 1 auto;
        }
    }
}

/***********Do not add in project*********/
:not(pre) > code[class*='language-'],
pre[class*='language-'] {
    background: var(--color-100) !important;
}

code[class*='language-'],
pre[class*='language-'] {
    text-shadow: none !important;
    color: var(--color-000) !important;
}

/***********icon-page*********/
.ico-title {
    font-size: 2em;
}

.iconlist {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .icon-holder {
        position: relative;
        text-align: center;
        border-radius: 3px;
        overflow: hidden;
        padding-bottom: 5px;
        border: 1px solid var(--border-color);
        transition: all 0.2s linear 0s;

        &:hover {
            background: var(--secondary-color);
            color: #ffffff;

            .icon i {
                color: #ffffff;
            }
        }

        .icon {
            padding: 20px;
            text-align: center;

            i {
                font-size: 3em;
                color: var(--font-color);
            }
        }

        span {
            font-size: 14px;
            display: block;
            margin-top: 5px;
            border-radius: 3px;
        }
    }
}

.new-data-wrapper {
    .table-hover > tbody > tr:hover {
        --bs-table-accent-bg: var(--color-100);
    }
    table.dataTable.dtr-inline.collapsed > tbody > tr[role='row'] > td:first-child:before,
    table.dataTable.dtr-inline.collapsed > tbody > tr[role='row'] > th:first-child:before {
        top: 50%;
        background-color: var(--primary-color);
        transform: translateY(-50%);
    }
    table.dataTable > tbody > tr.child ul.dtr-details {
        display: block;
    }

    div.dataTables_wrapper {
        .dataTables_length {
            @media only screen and (max-width: $break-small - 1px) {
                display: none;
            }
        }
        div.dataTables_filter {
            @media only screen and (max-width: $break-small - 1px) {
                text-align: left !important;
            }
        }
        div.dataTables_info {
            @media only screen and (max-width: $break-small - 1px) {
                display: none !important;
            }
        }
        div.dataTables_paginate {
            ul.pagination {
                @media only screen and (max-width: $break-small - 1px) {
                    justify-content: start !important;
                }
            }
        }
    }

    #priceTableup_wrapper,
    #priceTabledown_wrapper,
    #priceTableuponly_wrapper,
    #priceTabledownonly_wrapper {
        .dataTables_filter,
        .dataTables_length,
        .dataTables_info,
        .dataTables_paginate {
            display: none;
        }
    }
}

/*********coupon-design******/
.coupon {
    .price {
        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }

    .detail {
        border-left: 1px dashed var(--border-color);
        position: relative;

        @media (max-width: 576px) {
            border-left: none;
            border-top: 1px dashed var(--border-color);
            padding-top: 15px;
        }

        &::before,
        &::after {
            background: var(--body-color);
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            position: absolute;
            top: -25px;
            left: -15px;
            z-index: 1;
            border-radius: 50%;

            @media (max-width: 576px) {
                top: -18px;
                left: -19px;
            }
        }

        &::after {
            top: auto;
            bottom: -28px;

            @media (max-width: 576px) {
                top: -18px;
                left: auto;
                right: -19px;
            }
        }
    }
}

/**********Priceing*********/
.price-effect {
    background-color: var(--dark-color) !important;

    .card-body {
        background-color: var(--border-color);

        &.price-detail {
            position: relative;
        }
    }
}

/**********Dynamic Table*********/
.rdt_TableHeader {
    display: none !important;
}
.rdt_TableHeadRow {
    font-weight: bold;
}
.hLGqlg,
.hCBnvI,
.iAwKFK,
.bhoXfZ {
    background-color: var(--card-color) !important;
    color: var(--text-color) !important;
}
.rdt_TableRow {
    border-bottom: var(--border-color) !important;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
}
.rdt_TableCell {
    padding: 0.8rem 0.8rem;
}

/***********responsive*********/
@media only screen and (max-width: 554px) {
    .w-sm-100 {
        width: 100%;
    }
}

@media only screen and (max-width: 430px) {
    .profile-user {
        margin: 0 auto;
        padding-right: 0px !important;
    }

    .profile-card {
        .card-body {
            flex-direction: column;

            .profile-av {
                padding-right: 0px !important;

                &.w220 {
                    width: 100%;
                }

                .about-info {
                    justify-content: center !important;
                }
            }

            .profile-info {
                padding-left: 0px !important;
                border-color: transparent !important;
                text-align: center;

                .social {
                    justify-content: center;
                }

                .ct-btn-set {
                    justify-content: center;
                }
            }
        }
    }
}

.card-balance-wrapper {
    max-height: 600px;
    height: 100%;
}

.modal-fullscreen-sm-down {
    max-width: 900px;

    .modal-dialog {
        max-width: 900px;
        margin: 0;
    }
}

.durations-wrapper {
    .durations-list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;

        .active {
            background: #feb019;
            color: white !important;
        }

        .duration {
            padding: 4px;
            border: 1px solid #feb019;
            border-radius: 4px;
            color: #feb019;
            margin-right: 5px;
            cursor: pointer;
            transition: ease-in-out 0.2s;

            &:hover {
                color: white;
                background: #feb019;
            }
        }
    }
}

.calculation-loading-wrapper {
    .spinner-border {
        width: 1.3rem;
        height: 1.3rem;
        margin-right: 5px;
    }
}

.btn-loading-fixed {
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-border {
        margin-left: 10px;
    }
}

.exchange-btn {
    background-color: #646d8dc2 !important;
    border-color: #646d8dc2 !important;
    color: 'white';

    &:hover {
        background-color: #646d8d !important;
        border-color: #646d8d !important;
    }
}

.cfd-header-wrapper {
    display: flex;
    align-items: center;

    .info-wrapper {
        .name {
            margin-left: 5px;
            font-size: 0.9rem;
        }
    }
}

.delete-link {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.ml-1 {
    margin-left: 3px;
}
.mt-1 {
    margin-top: 5px;
}

.d-flex-column {
    display: flex;
    flex-direction: column;
}

.card-summary-wrapper {
    display: flex;
    align-items: center;
}
.color-price-summary {
    color: #646d8d;
}
.col-summary-wrapper {
    &:last-child {
        padding-right: 0 !important;
    }
}

.bs-container-reset {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.app-logo-wrapper {
    width: 160px;
}

.app-logo-auth {
    width: 130px;
}
.sidebar-toggled {
    margin: auto;
    .sidebar-toggled-logo {
        width: 35px;
        border-radius: 4px;
    }
}

.trading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pair-wrapper {
    display: flex;
    align-items: center;
    color: #646d8d;
}

.pair-img {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
}

.trading-spinner {
    color: #695bd8;
    margin-right: 5px;
}

.trading-icons-wrapper {
    display: flex;
    align-items: center;
    .fa {
        margin-left: 15px;
        cursor: pointer;
    }
}
.img-pair {
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
}
.bs-modal-xs {
    max-width: 500px !important;
}

.dot-yellow {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #e89b36;
    border: #e89b36;
}

.dot-gray {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #cccccc;
    border: #cccccc;
}

.dot-wrapper {
    max-width: 65px;
}

.cc-small-img {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
}

.countdown-time {
    color: #646d8d;
    font-weight: 500;
}

.signals-form-wrapper {
    .signals-amount-input {
        height: 35px;
        max-width: 190px;
    }
}

.cfd-range-input {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > :nth-child(1) {
        text-align: left;
    }
    > :nth-child(2) {
        text-align: left;
    }
    > :nth-child(3) {
        text-align: left;
        margin-left: 5px;
    }
    > :nth-child(4) {
        text-align: left;
    }
    > :nth-child(5) {
        text-align: center;
    }
    > :nth-child(6) {
        text-align: right;
    }
    > :nth-child(7) {
        text-align: center;
    }

    .item {
        cursor: pointer;
        font-size: 0.9rem;
        width: 9%;

        @media only screen and (max-width: 475px) {
            font-size: 0.7rem;
        }

        @media only screen and (max-width: 390px) {
            font-size: 0.6rem;
        }

        @media only screen and (max-width: 345px) {
            font-size: 0.5rem;
        }
    }
    .active {
        color: #646d8d !important;
        font-weight: 600;
    }
}

.a-link {
    cursor: pointer;
    transition: ease-in-out 0.3s;
    &:hover {
        text-decoration: underline;
    }
}

.mobile-close {
    display: none;
}

.header-option-icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-form-wrapper {
    .input-group {
        &:not(.has-validation) {
            > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
            > .dropdown-toggle:nth-last-child(n + 3) {
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
                .invalid-feedback
            ) {
            margin-right: -1px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
        }
    }
    .btn-group {
        .btn:first-child {
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }
        > .btn:not(:last-child):not(.dropdown-toggle),
        > .btn-group:not(:last-child) > .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        > .btn:nth-child(n + 3),
        > :not(.btn-check) + .btn,
        > .btn-group:not(:first-child) > .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
        }
    }
    .sidebar-mini-btn {
        .fa-arrow-left {
            transform: rotate(180deg);
        }
    }
    .dropdown-toggle::after {
        margin-left: unset;
        margin-right: 0.255em;
    }
    div.dataTables_wrapper div.dataTables_filter {
        text-align: end;
    }

    @media (min-width: 992px) {
        .me-lg-0 {
            margin-left: 0 !important;
        }
        .pe-lg-4 {
            padding-left: 1.5rem !important;
            padding-right: 0 !important;
        }
    }
    @media (min-width: 768px) {
        .ms-md-5 {
            margin-right: 3rem !important;
        }
        .text-md-start {
            text-align: right !important;
        }
        .dropdown-menu-md-end[data-bs-popper] {
            right: auto;
            left: 0;
        }
        .text-md-end {
            text-align: left !important;
        }
    }
}

.table-fixed-height {
    max-height: 300px;
}

.currency-wrapper {
    width: 195px;
}

.cfd-card-header {
    display: flex;
    justify-content: space-between;
}

.disabled-link {
    .m-link {
        pointer-events: none;
    }
    .ms-link {
        pointer-events: none;
    }
}

.recovery-field-wrapper {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
}

.recovery-wrapper {
    .info-header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 1rem;

        i {
            font-size: 3rem;
            color: #4dca88;
        }
        .info-title {
            color: #4dca88;
            font-size: 1.2rem;
            margin-top: 0.2rem;
        }
    }

    .info-body-warning {
        margin-bottom: 1rem;
        font-weight: 500;
        color: #e1960c;

        i {
            margin-right: 5px;
        }
    }

    p,
    ol {
        color: #6b7078;
    }
}

.swal2-container {
    z-index: 999999;
}

.exchange-calculate {
    padding: 1.1rem 0;
}

.rec-form {
    display: flex;
    align-items: center;
    .rec-input {
    }

    .btn-wrapper {
        margin-left: 2rem;
    }
}
