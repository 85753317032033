/*
* Admin Layout (cryptoon)
* @author: Pixelwibes
* @design by: Pixelwibes.
* @event-namespace:cryptoon
* Copyright 2022 Pixelwibes
*/

// Chat app page scss
.card-chat,
.card-chat-body{
    height: 100vh;
}

// left sidebar chat list, groups and contact
.chatlist-toggle{
    color: var(--primary-color);
}

.card-chat{
    min-width: 380px;

    @media only screen and (max-width: $break-medium - 1px) {
        @include transition(ease .1s);
        position: fixed;
        left: -600px;
        z-index: 9;

        &.open{
            left: 0;
        }
    }
    @media only screen and (max-width: $break-small - 1px) {
        &.open{
            min-width: 300px;
            width: 300px;
        }
    }
    @media only screen and (max-width: 360px) {
        &.open{
            min-width: 260px;
            width: 260px;
        }
    }

    .tab-content{
        @include overflow(scroll);
        overflow-y: auto;
        height: calc(100vh - 150px);
    }

    .list-group{
        .list-group-item{
            &.open,
            &:hover{
                background-color: var(--body-color);
            }
            a{
                color: var(--color-500);
            }
        }
        .msg-time{
            font-size: 11px;
            font-weight: 600;
        }
    }
    .nav-link{
        @media only screen and (max-width: $break-small - 1px) {
            padding: 5px ;
        }
    }
}

// 
.card-chat-body{

    @media only screen and (max-width: $break-small - 1px) {
        height: calc(100vh - 60px);
    }

    .max-width-70{
        max-width: 70%;

        @media only screen and (max-width: $break-small) {
            max-width: 100%;
        }
    }

    .chat-history{
        @include overflow(scroll);
        height: 100vh;
        overflow-y: auto;
    }

    .chat-message{
        position: relative;
        .btn.btn-dark{
            position: absolute;
            right: 10px;
            top: 14px;
        }
        .action-bar{
            position: absolute;
            right: 10px;
            top: 10px;
        }
        .form-control{
            padding-right: 80px;
        }
    }

    .text-right{
        .message{
            color: $white;
        }    
    }
    .message{
        color: var(--color-600);
    }

    &.card{
        background-color: var(--body-color);
    }
}
