$card-data: (
    exchange: 610px,
    trading: 565px,
    cfd: 710px
);

@each $card, $card-height in $card-data {
    .#{$card}-card {
        height: $card-height;
    }

    .#{$card}-chart {
        height: $card-height - 32px;
    }
}
